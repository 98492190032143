import React, { useState } from "react";
import Login from "../images/login.png";
import { CgProfile } from "react-icons/cg";
import { CiLock, CiUnlock } from "react-icons/ci";
import { motion } from "framer-motion";
import { fadeIn } from "../Varient";
import { Link } from "react-router-dom";
import './chat.css';
const Chat = () => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = () => {
    setShowPassword((prevState) => !prevState);
  };
  return (
    <div
      className="bg-cover bg-center bg-repeat"
      style={{ backgroundImage: `url(${Login})` }}
    >
      <motion.div
      variants={fadeIn("right", 0.3)}
      initial="hidden"
      whileInView={"show"}
      viewport={{ once: false, amount: 0.7 }}
      className="pt-28 text-center">
        <h2
          className="pb-4 font-kurdish_042 text-2xl text-white"
        >
          تائێستە خۆت تۆمارنەکردوە؟
        </h2>
      </motion.div>
      <motion.div
       variants={fadeIn("left", 0.3)}
       initial="hidden"
       whileInView={"show"}
       viewport={{ once: false, amount: 0.7 }}
      className="pt- text-center">
        <button
          className="btn w-[120px] rounded-[48.5px] text-md border bg-white text-center align-middle font-kurdish_043 text-primary-100"
        >
          خۆت تۆماربکە
        </button>
      </motion.div>
      <div className="mt-6 flex h-[560px] flex-col items-center justify-center space-y-8">
        <motion.div
        variants={fadeIn("right", 0.3)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.7 }}
        className="flex justify-center">
          <h2
            className="custom-divider w-80 text-center font-kurdish_042 text-4xl text-primary-200"
          >
            بەخێر بێیت
          </h2>
        </motion.div>
        {/* ناوی بەکارهێنەر */}
        <motion.div
          variants={fadeIn("left", 0.3)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}
          className="relative flex justify-center text-white"
        >
          <input
            type="text"
            className="h-14 w-80 rounded-[48.5px] bg-gradient-to-r from-primary-100 via-[#695DBC] placeholder-opacity-75 
            to-primary-200 text-center placeholder:font-kurdish_042 text-white outline-none focus:outline-none placeholder-slate-200"
            placeholder="ناوی بەکارهێنەر"
          />
          <CgProfile
            size={30}
            className="pointer-events-none absolute right-2 top-[50%] -translate-y-1/2 transform stroke-current font-thin text-white opacity-60"
          />
        </motion.div>
        {/* پاسۆردی بەکارهێنەر */}
        <motion.div
          variants={fadeIn("right", 0.3)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}
          className="relative flex justify-center"
        >
          <input
            type={showPassword ? "text" : "password"}
            className="h-14 w-80 rounded-[48.5px] bg-transparent bg-gradient-to-r placeholder-slate-200 placeholder-opacity-75 
           from-primary-100 via-[#695DBC] to-primary-200 text-center placeholder:font-kurdish_042 text-white caret-white outline-none focus:outline-none"
            placeholder="وشەی نهێنی"
          />
          {showPassword ? (
            <CiUnlock
              size={30}
              className="absolute right-2 top-[50%] -translate-y-1/2 transform cursor-pointer stroke-current font-thin text-white opacity-60"
              onClick={togglePassword}
            />
          ) : (
            <CiLock
              size={30}
              className="absolute right-2 top-[50%] -translate-y-1/2 transform cursor-pointer stroke-current font-thin text-white opacity-60"
              onClick={togglePassword}
            />
          )}
        </motion.div>
        <motion.div
         variants={fadeIn("left", 0.3)}
         initial="hidden"
         whileInView={"show"}
         viewport={{ once: false, amount: 0.7 }}
        className="flex justify-center">
          <button
            className="btn w-[120px] rounded-[48.5px] items-end bg-gradient-to-r from-primary-200 to-primary-100 
            pb-2 font-kurdish_043 text-lg font-thin text-white"
          >
            داخڵ ببە
          </button>
        </motion.div>
        <motion.div
        
        variants={fadeIn("right", 0.3)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.7 }}
        className="flex justify-center">
          <Link
            to="/location"
            className="font-kurdish_041 text-primary-100"
            href="#"
          >
            وشەی نهێنیت لەبیر کردووە؟
          </Link>
        </motion.div>
      </div>
    </div>
  );
};

export default Chat;
