import React, { useState } from "react";
import robot from "../images/rob1.png";
import { CgProfile } from "react-icons/cg";
import { MdOutlineEmail } from "react-icons/md";
import { CiLock, CiUnlock } from "react-icons/ci";
import { motion } from "framer-motion";
import { fadeIn } from "../Varient";
import { Link } from "react-router-dom";
const Location = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordTwo, setShowPasswordTwo] = useState(false);

  const togglePassword = () => {
    setShowPassword((prevState) => !prevState);
  };
  const togglePasswordTwo = () => {
    setShowPasswordTwo((prevState) => !prevState);
  };

  return (
    <div className="h-screen snap-y snap-proximity flex-col justify-center space-y-5 bg-primary-200">
      <div className="space-y-4">
        <div className="mx-4 flex justify-start -space-x-4 pl-5">
          <motion.img
            variants={fadeIn("right", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.7 }}
            src={robot}
            className="w-38 ml-[30px] h-[170px] max-sm:ml-0"
            alt="robot"
          />
          <motion.h2
            variants={fadeIn("left", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.7 }}
            className="flex items-center font-kurdish_043 text-xl text-white max-sm:text-xl"
          >
            سڵاو بەخێربێیت
          </motion.h2>
        </div>
        {/* profile input */}
        <div className="flex justify-center">
          <motion.div
            variants={fadeIn("right", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.7 }}
            className="relative"
          >
            <input
              type="text"
              className="text-md h-12 w-[350px] rounded-[48.5px] border bg-transparent py-8 pl-8
              text-center placeholder:font-kurdish_042 placeholder-opacity-75 text-white caret-white focus:border-white focus:outline-none"
              placeholder="ناوی بەکارهێنەر"
            />
            <CgProfile
              size={40}
              className="pointer-events-none absolute right-2 top-[50%] -translate-y-1/2 transform stroke-current font-thin text-white opacity-60"
            />
          </motion.div>
        </div>
        {/* email input */}
        <div className="flex justify-center">
          <motion.div
            variants={fadeIn("left", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.7 }}
            className="relative"
          >
            <input
              type="email"
              className="text-md h-12 w-[350px] rounded-[48.5px] border bg-transparent py-8 pl-8
              text-center placeholder:font-kurdish_042 placeholder-opacity-75 text-white caret-white focus:border-white focus:outline-none"
              placeholder="ئیمەیڵ"
            />
            <MdOutlineEmail
              size={40}
              className="pointer-events-none absolute right-2 top-[50%] -translate-y-1/2 transform stroke-current font-thin text-white opacity-60"
            />
          </motion.div>
        </div>
        {/* password input */}
        <div className="flex justify-center">
          <motion.div
            variants={fadeIn("right", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.7 }}
            className="relative"
          >
            <input
              type={showPassword ? "text" : "password"}
              className="text-md h-12 w-[350px] rounded-[48.5px] border bg-transparent py-8 pl-8
              text-center placeholder:font-kurdish_042 placeholder-opacity-75 text-white caret-white focus:border-white focus:outline-none"
              placeholder="وشەی نهێنی"
            />
            {showPassword ? (
              <CiUnlock
                size={40}
                className="absolute right-2 top-[50%] -translate-y-1/2 transform cursor-pointer stroke-current font-thin text-white opacity-60"
                onClick={togglePassword}
              />
            ) : (
              <CiLock
                size={40}
                className="absolute right-2 top-[50%] -translate-y-1/2 transform cursor-pointer stroke-current font-thin text-white opacity-60"
                onClick={togglePassword}
              />
            )}
          </motion.div>
        </div>
        {/* confirm input */}
        <div className="flex justify-center">
          <motion.div
            variants={fadeIn("left", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.7 }}
            className="relative"
          >
            <input
              type={showPasswordTwo ? "text" : "password"}
              className="text-md h-12 w-[350px] rounded-[48.5px] border bg-transparent py-8 pl-8
              text-center placeholder:font-kurdish_042 placeholder-opacity-75 text-white caret-white focus:border-white focus:outline-none"
              placeholder="وشەی نهێنی"
            />
            {showPasswordTwo ? (
              <CiUnlock
                size={40}
                className="absolute right-2 top-[50%] -translate-y-1/2 transform cursor-pointer stroke-current font-thin text-white opacity-60"
                onClick={togglePasswordTwo}
              />
            ) : (
              <CiLock
                size={40}
                className="absolute right-2 top-[50%] -translate-y-1/2 transform cursor-pointer stroke-current font-thin text-white opacity-60"
                onClick={togglePasswordTwo}
              />
            )}
          </motion.div>
        </div>

        <motion.div
          variants={fadeIn("right", 0.3)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}
          className="flex justify-center"
        >
          <button className="btn w-[120px] rounded-[48.5px] border bg-white font-kurdish_043 text-xl text-primary-100">
            تۆمارکردن
          </button>
        </motion.div>
        <motion.div
          variants={fadeIn("right", 0.3)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}
          className="flex flex-row-reverse justify-center align-middle"
        >
          <p className="text-white font-kurdish_041 pl-1">
          ئەکاونتت هەیە؟
          </p>
          <Link to="/chat" className="rounded-[48.5px] font-kurdish_043 text-white text-sm pt-[2px] font-semibold">
            داخڵ ببە
          </Link>
        </motion.div>
      </div>
    </div>
  );
};

export default Location;
