import React from "react";
import { SlHome } from "react-icons/sl";
import { CiLocationOn } from "react-icons/ci";
import { IoAddSharp } from "react-icons/io5";
import { HiOutlineChatBubbleLeftRight } from "react-icons/hi2";
import { CgProfile } from "react-icons/cg";
import { Link } from "react-router-dom";
const Navigation = () => {
  return (
    <div className="fixed bottom-0 left-0 z-50 mx-auto flex w-full bg-gray-200 p-4">
      <ul className="flex w-full items-center justify-around">
        <li>
          <Link to="/">
            <SlHome size={27} className="text-primary-200" />
          </Link>
        </li>
        <li>
          <Link to="/location">
            <CiLocationOn size={27} className="text-primary-200" />
          </Link>
        </li>
        <li>
          <IoAddSharp
            size={35}
            className="text-primary rounded-xl border-[3px] border-purple-600"
          />
        </li>
        <li>
          <Link to="/chat">
            <HiOutlineChatBubbleLeftRight
              size={27}
              className="text-primary-200"
            />
          </Link>
        </li>
        <li>
          <Link to="/profile2">
            <CgProfile size={27} className="text-primary-200" />
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Navigation;
