import React from "react";
// import {
//   IoChatboxEllipsesOutline,
//   IoHeartOutline,
//   IoHeart,
// } from "react-icons/io5";
// import { FiPhoneCall } from "react-icons/fi";

const Post = () => {
  const posts = [
    {
      src: "https://i.pinimg.com/564x/bd/33/c1/bd33c1ed140689c84c51534e0f695315.jpg",
      alt: "Home",
      price: "270,000$",
      location: "سلێمانی ئاشتی",
    },
    {
      src: "https://i.pinimg.com/564x/ab/1f/86/ab1f8617c6ed34d73f26a7c03f51a7ba.jpg",
      alt: "Home",
      price: "170,000$",
      location: "سلێمانی ڕزگاری",
    },
    {
      src: "https://i.pinimg.com/564x/45/11/c8/4511c8e2158d40cb2cc866f716d34b87.jpg",
      alt: "Home",
      price: "100,000$",
      location: "سلێمانی کارێزەووشک",
    },
    {
      src: "https://i.pinimg.com/736x/05/ac/dd/05acdd47e297a4db63ce65c6032ac4f6.jpg",
      alt: "Home",
      price: "200,000$",
      location: "سلێمانی بەختیاری",
    },
    {
      src: "https://i.pinimg.com/564x/3e/2d/90/3e2d90bc910250ad93cad42b2309e165.jpg",
      alt: "Home",
      price: "320,000$",
      location: "سلێمانی کوردسات",
    },
  ];

  return (
    
    <>
    <div className="flex flex-col align-middle mt-4 space-y-5 px-3 snap-y snap-proximity h-[700px]">
        {posts.map((post,index) =>(

        <div className="card card-side rounded-lg shadow-lg snap-end ">
          <img className="max-sm:w-30 w-[150px] h-25 rounded-l-lg" src={post.src} alt={post.alt} />
          <div className="card-body flex-col p-0 pr-2">
            <p className="flex justify-end font-medium max-sm:text-sm text-md pt-2 font-kurdish_043">{post.location}</p>
            <h3 className=" pb-2 text-right font-kurdish_042">{post.price}</h3>
          </div>
        </div>
        ))}
        
    </div>
    </>
  );
};

export default Post;
